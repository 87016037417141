<template lang="pug">
v-autocomplete(
  dense
  outlined
  hide-details
  :items="states"
  v-model="stateId"
  :label="label"
  item-text="full"
  :multiple="multiple"
  item-value="id"
  loading="isPending"
  @change="set"
)
</template>

<script>
import { ref } from '@vue/composition-api'
import useStates from '@/helpers/useStates'

export default {
  name: 'StateList',

  props: {
    value: {
      type: [Array, Number],
      required: true,
      default: 0
    },
    label: {
      type: String,
      required: false,
      default: 'State'
    },
    multiple: {
      type: Boolean
    }
  },

  methods: {
    set() {
      this.$emit('input', this.stateId)
    }
  },

  setup(props, { root }) {
    const { states, isPending } = useStates()
    const stateId = ref(props.value)

    return {
      stateId,
      states,
      isPending
    }
  }
}
</script>
