<template lang="pug">
.org-create-edit-dialog
  CreateEditDialog(
    title="Add Organization"
    :item="organization"
    :isOpen="isOpen"
    @close="$emit('close')"
    @submit-form="saveOrg"
  )
    template(
      v-slot:form="{ clone, rules }"
    )
      v-text-field(
        outlined
        dense
        label="Organization Name"
        v-model="clone.name"
        :rules="[rules.required]"
      )

      AddressForm(
        :rules="rules"
        :clone="clone"
        addressKey="address"
      )
</template>

<script>
import formValidations from '@/helpers/formValidations'
import AddressForm from '@/components/Address.Form'
import CreateEditDialog from '@/components/Dialogs/CreateEdit.Dialog'
import { ref } from '@vue/composition-api'

export default {
  name: 'OrgCreateEditDialog',

  props: {
    title: {
      type: String,
      default: 'Add Organization'
    },
    isOpen: Boolean,
    editingOrg: Object
  },

  components: {
    AddressForm,
    CreateEditDialog
  },

  setup(props, { root: { $FeathersVuex, $snackError, $router } }) {
    const { valid, rules } = formValidations()
    const { Organization } = $FeathersVuex.api
    const createEditForm = ref(null)
    const organization = new Organization(props.editingOrg)

    const saveOrg = async ({ save, reset }) => {
      try {
        await save({ query: { $assignOrgId: true } });
        reset()
        $router.go(0);
      } catch (e) {
        $snackError(e.message)
      }
    }

    return {
      valid,
      rules,
      createEditForm,

      organization,
      saveOrg
    }
  },
}
</script>

<style>

</style>