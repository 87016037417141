export default function () {
  return {
    valid: false,
    rules: {
      required: value => (Array.isArray(value) ? value.length > 0 : !!value) || 'Required',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return (!value || pattern.test(value)) || 'Invalid e-mail'
      },
      phone: value => {
        const pattern = /^[0-9()-]+$/
        return (!value || (pattern.test(value) && value.length >= 10)) || 'Must be a valid phone number'
      },
      isEightCharacters: v => v.length >= 8 || 'Must be at least 8 characters',
      isMatch: (matchingValue) => {
        return value => (matchingValue === value)
          ? true : 'Passwords must match'
      },
      isNumeric: v => {
        return (!v || /^-?\d+$/.test(v)) || 'Numbers only'
      },
      isMoney: v => {
        const isCurrency = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/.test(v);
        const numVal = ("" + v).replace(/\$|\.|,/g, '');
        const isNum = /^-?\d+$/.test(numVal)
        return (!v || (isCurrency && isNum)) || 'Invalid Money Format'
      },
      max: amt => {
        return v =>
          !v || (v && v.length <= amt || `Cannont be more than ${ amt } characters long`)
      }
    }
  }
}