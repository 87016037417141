import { useFind } from 'feathers-vuex'
import { computed, ref } from '@vue/composition-api'
import feathersClient from './../api-client'

const useStates = () => {
  const {
    FeathersVuexModel: State
  } = feathersClient.service('states');
  const params = computed(() => ({
    query: { $limit: 100 }
  }))
  const { isPending, find } = useFind({ model: State, params, immediate: false })

  const states = computed(() => {
    const stored = State.findInStore(params);
    if (stored?.data.length) return stored.data;

    const _states = ref([])

    find(params)
    .then(response => _states.value = response)
    .catch(e => {
      console.log(e, 'Error attempting to query states')
    })

    return _states.value
  })

  const findByStore = (key, value) => {
    const data = states.value;
    let state = { id: 0 }

    if (data.length) {
      const found = data.find(d => d[key] === value)
      if (found) state = found
    }

    return state
  }

  return {
    states,
    isPending,
    findByStore
  }
}

export default useStates