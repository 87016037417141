<template lang="pug">
.address-form
  v-text-field(
    outlined
    dense
    label="Address"
    v-model="(addressKey ? clone[addressKey] : clone).street"
    :rules="[val => skipValidation ? true : rules.required(val)]"
  )

  .d-flex.justify-content-between.gap-8
    v-text-field(
      outlined
      dense
      label="City"
      v-model="(addressKey ? clone[addressKey] : clone).city"
      :rules="[val => skipValidation ? true : rules.required(val)]"
    )

    StateList(
      v-model="(addressKey ? clone[addressKey] : clone).stateId"
    )

    v-text-field(
      outlined
      dense
      label="ZipCode"
      v-model="(addressKey ? clone[addressKey] : clone).zipCode"
      :rules="[val => skipValidation ? true : rules.required(val), rules.isNumeric]"
    )
</template>

<script>
import StateList from '@/components/StateList'

export default {
  name: 'AddressForm',

  components: {
    StateList
  },

  props: {
    clone: {
      type: Object,
      required: true
    },

    rules: {
      type: Object,
      required: true
    },

    addressKey: {
      type: String,
      required: false
    },

    skipValidation: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  setup(props) {}
}
</script>
