<template lang="pug">
.common-create-edit-dialog
  v-dialog(
    max-width="600"
    persistent
    v-model="isOpen"
  )
    FeathersVuexFormWrapper(
      :item="item"
      :eager="false"
    )
      template( v-slot="{ save, reset, clone }" )
        v-form( 
          ref="createEditForm" 
          v-model="valid"
          @submit.prevent="saveForm({ save, reset, clone })"
        )
          v-card
            v-card-title.bg-white.border-b.justify-between
              div {{ title }}
              v-spacer
              div
                v-btn(
                  fab
                  small
                  depressed
                  @click="$emit('close')"
                )
                  v-icon mdi-close

            v-card-text.pa-8.py-10
              slot(
                name="form" 
                v-bind:save="save" 
                v-bind:reset="reset" 
                v-bind:clone="clone" 
                v-bind:rules="rules"
              )

            v-card-actions( right ).bg-gray-100
              slot( name="actions" )
                v-btn(
                  depressed
                  @click="$emit('close')"
                ) Cancel 
                v-btn(
                  depressed
                  type="submit"
                  :disabled="!valid"
                  color="primary"
                ) {{ saveAction }}
</template>

<script>
import formValidations from '../../helpers/formValidations'

export default {
  name: 'CreateEditDialog',
  
  props: {
    isOpen: {
      required: true,
      type: Boolean,
      default: false
    },

    item: {
      type: Object,
      required: true
    },

    title: {
      type: String,
      required: false,
      default: 'Add'
    },

    saveAction: {
      type: String,
      required: false,
      default: 'Save'
    }
  },

  setup(props, { emit }) {
    const { valid, rules } = formValidations()
    const saveForm = ({ save, reset, clone }) => 
      emit('submit-form', { save, reset, clone })

    return { 
      valid,
      rules,
      saveForm
    }
  }
}
</script>

<style>

</style>